// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-bar {
  background-color: black;
  color: white;
  text-align: center;
  padding: 0.8rem 0; /* Adjust padding as needed */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  overflow: hidden;
}

.marquee-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee-custom {
  display: inline-block;
  padding-left: 100%; /* Start from outside the viewport */
  animation: marquee-custom 20s linear infinite;
}

@keyframes marquee-custom {
  0% {
    transform: translateX(0); /* Start from the right edge */
  }
  100% {
    transform: translateX(-100%); /* Move to the left edge */
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/NotificationBar.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB,EAAE,6BAA6B;EAChD,eAAe;EACf,MAAM;EACN,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB,EAAE,oCAAoC;EACxD,6CAA6C;AAC/C;;AAEA;EACE;IACE,wBAAwB,EAAE,8BAA8B;EAC1D;EACA;IACE,4BAA4B,EAAE,0BAA0B;EAC1D;AACF","sourcesContent":[".notification-bar {\n  background-color: black;\n  color: white;\n  text-align: center;\n  padding: 0.8rem 0; /* Adjust padding as needed */\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 50;\n  overflow: hidden;\n}\n\n.marquee-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.marquee-custom {\n  display: inline-block;\n  padding-left: 100%; /* Start from outside the viewport */\n  animation: marquee-custom 20s linear infinite;\n}\n\n@keyframes marquee-custom {\n  0% {\n    transform: translateX(0); /* Start from the right edge */\n  }\n  100% {\n    transform: translateX(-100%); /* Move to the left edge */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
